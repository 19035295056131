export default class EditExam {
    constructor() {
        this.seconds_left = TIME_LEFT * 1000
        this.countdown = document.querySelector("#countdown")
        this.finishBtn = document.querySelector("#finish-btn")
        this.interval = setInterval(() => this.updateTime(), 1000)
        this.events()
        this.start()
    }

    start() {
        let questions = localStorage.getItem("questions")
        if(!questions) return false
        questions.split("_").forEach(el => {
            const value = localStorage.getItem(el)
            const radio = document.querySelector(`#${value}`)
            radio.checked = true
        })
    }

    events() {
        const radios = document.querySelectorAll("input[type='radio'].form-check-input:not(:checked)")
        radios.forEach(radio => this.onClickRadio(radio))
    }

    onClickRadio(_radio) {
        _radio.addEventListener("click", () => {
            const _name = _radio.getAttribute("name").replace("exam[answers]","")
            const _value = _radio.getAttribute("id")
            localStorage.setItem(_name, _value)
            //
            this.finishBtn.classList.remove("disabled")
            //
            this.addToPile(_name)
        })
    }

    addToPile(_name) {
        let questions = localStorage.getItem("questions")
        if (questions) {
            if (questions.indexOf(_name) < 0) {
                questions += `_${_name}`
                localStorage.setItem("questions", questions) //Reassign
            }
        } else {
            localStorage.setItem("questions", _name)
        }
    }

    get left_time() {
        return {
            hours: this.leftZero((this.seconds_left % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: this.leftZero((this.seconds_left % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: this.leftZero((this.seconds_left % (1000 * 60)) / 1000)
        }
    }

    // Está bien vg el nombre de Left Zero como para una banda (O quizá sólo estoy muy pacheco)
    leftZero(_number) {
        // Parseamos a entero y luego a String
        // ~~ es más rápido que Math.floor y hace lo mismo
        _number = String(~~_number)
        return _number.length == 1 ? `0${_number}` : `${_number}`
    }

    writeTime() {
        let count_down = ""
        if (this.left_time.hours > 0) count_down += `${this.left_time.hours}:`
        if (this.left_time.minutes > 0) count_down += `${this.left_time.minutes}:`
        count_down += `${this.left_time.seconds}`
        this.countdown.innerHTML = count_down
    }

    updateTime() {
        this.seconds_left = this.seconds_left - 1000
        this.writeTime()
        if (this.seconds_left < 0) {
            this.timeIsUp()
        }
    }

    timeIsUp() {
        this.form = document.querySelector("#exam-form")
        this.form.submit()
    }
}