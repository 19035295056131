// modules
import Exam from "../exams/main"
import Preloader from "../components/preloader.js"
import Payment from "../modules/payment"
import Sliders from "../modules/slider"
import gsap from "gsap"

class App {
    constructor() {
        this.preloader = new Preloader()
        this.events()
        this.exam = new Exam(EXAM)
        this.payment = new Payment()
        this.sliders = new Sliders()
    }

    events() {
        let links = document.querySelectorAll(".change-page")
        links.forEach(link => link.addEventListener("click", (e) => this.onChangePageClick(e), false) )
    }

    onChangePageClick(event) {
        event.preventDefault()
        const href = event.target.getAttribute("href")
        gsap.set("#preloader", {
            scaleX: 1,
            scaleY: 1
        })
        gsap.to("#preloader", {
            duration: .15,
            display: "block",
            opacity: 1,
            onComplete: () => {
                window.location.href = href
            }
        })
    }

    load() {
        gsap.to("#preloader", {
            duration: .35,
            opacity: 0,
            display: "none",
            y: "100%",
            scaleX: 1,
            scaleY: 1
        })
    }
}

const _app = new App()
window.addEventListener("load", () => _app.load())