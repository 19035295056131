export default class NewExam {
    constructor() {
        this.program_select = document.querySelector("#exam_program_id")
        this.evaluation_select = document.querySelector("#exam_evaluation_id")
        this.evaluations = document.querySelectorAll("#exam_evaluation_id option")
        this.evaluationContainer = document.querySelector("#evaluation-container")
        this.themes_input = document.querySelector("#exam_selected_themes_ids")
        this.selectThemeBtns = document.querySelectorAll(".select-theme-btn")
        this.optionalsCount = 0

        this.events()
    }

    events() {
        // nueva evaluación
        if (this.program_select) this.program_select.addEventListener("change", () => this.onProgramChange())
        if (this.evaluation_select) this.evaluation_select.addEventListener("change", () => this.onEvaluationChange())
        if (this.selectThemeBtns) this.selectThemeBtns.forEach(btn => this.selectBtnEvent(btn))
    }

    onProgramChange() {
        this.hideThemesSelector()
        // Mostramos contendor de evaluaciones
        this.evaluationContainer.style.display = "block"
        this.evaluation_select.value = ""
        //Se ocultan todas las opciones de evaluaciones
        this.evaluations.forEach(el => {
            el.setAttribute('disabled', true)
            el.style.display = "none"
        })
        // Se muestran sólo evaluacions relacionadas al módulo seleccionado
        let visibles = this.evaluation_select.querySelectorAll(`.option-${this.program_select.value}`)
        visibles.forEach(el => {
            el.removeAttribute('disabled')
            el.style.display = "block"
        })
    }

    onEvaluationChange() {
        this.hideThemesSelector()
        const evaluation_id = this.evaluation_select.value
        // Se muestra materias a elegir. (EN CASO DE QUE TENGAN HABILITADOS LA SELECCIÓN DE OPCIONALES)
        const themes_container = document.querySelector(`#evaluation-themes-${evaluation_id}`)
        if (themes_container) themes_container.style.display = "block"
        this.themes_input.value = ""
        this.optionalsCount = document.querySelector(`option[value='${evaluation_id}']`).dataset.optionals
    }

    selectBtnEvent(_btn) {
        _btn.addEventListener("click", () => {
            if (!_btn.classList.contains("active") && this.selected_themes.length >= this.optionalsCount) {
                return false
            }
            _btn.classList.toggle("active")
            let themes_ids = []
            this.selected_themes.forEach(input => themes_ids.push(input.dataset.id) )
            this.themes_input.value = themes_ids.join(",")
            return _btn.blur()
        })
    }

    get selected_themes() {
        return document.querySelectorAll(".select-theme-btn.active")
    }

    hideThemesSelector() {
        const themes_containers = document.querySelectorAll(".evaluation-themes")
        themes_containers.forEach(el => el.style.display = "none")
    }

}