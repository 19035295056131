import NewExam from "./new"
import EditExam from "./edit"

export default class Exam {
    constructor(_action) {
        switch (_action) {
            case "new":
                this.exam = new NewExam()
                break;
            case "edit":
                this.exam = new EditExam()
                break;
        }
    }
}