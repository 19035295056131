export default class Sliders {
    constructor() {
        this.sliders = document.querySelectorAll(".swiper")
        this.sliders.forEach( el => this.setup(el))
    }

    setup(_container) {
        let swiper = new Swiper(_container, {
            keyboard: {
                enabled: true,
            },
            pagination: {
                clickable: true,
                el: _container.querySelector('.swiper-pagination'),
                renderBullet: (index, className) => {
                    return '<span class="' + className + ' index-item">' + (index + 1) + "</span>"
                }
            },
            autoHeight: true,
            navigation: {
                nextEl: _container.querySelector('.swiper-button-next'),
                prevEl: _container.querySelector('.swiper-button-prev'),
            }
        }).on("slideChange", (idx) => {
            let width = _container.querySelector('.swiper-pagination').getBoundingClientRect().width
            let item = _container.querySelectorAll(".swiper-pagination-bullet")[swiper.activeIndex]
            _container.querySelector('.swiper-pagination').scrollTo(item.offsetLeft, 0)
            if (item.offsetLeft > _container.querySelector('.swiper-pagination').scrollLeft) {
            }
        })
    }
}