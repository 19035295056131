export default class Payment {
    constructor() {
        this.input = document.querySelector("#voucher-payment")
        if (this.input) this.events()
    }

    events() {
        this.input.addEventListener("change", (e) => {
            console.log(e.target)
        })
    }
}